export const AFPartner = {
    partner: [
      {
        "FoundationName": "South Florida Regional Planning",
        "link": "http://sfregionalcouncil.org/",
      },
     
    ],
  }
  
  export default AFPartner
  